import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Navbar from "./components/navbar";

import Home from "./pages/home";
import Contact from "./pages/contact";
import Create from './pages/create';
import Bio from './pages/bio';

function App() {  

  return (
    <>

      <BrowserRouter>
        <Navbar />
        <Switch>
          <Route exact path="/" component={()=> <Home />}/>
          <Route exact path="/contact" component={()=> <Contact />} />
          <Route exact path="/create" component={Create}/>          
          <Route path="/bio" component={Bio} />
        </Switch>
    </BrowserRouter>
    </>
  );
}

export default App;
