import { useEffect, useState } from 'react';
import '../styles/zoom.css';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import Zoom from 'react-medium-image-zoom';

export default function ZoomableImage(props) {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Calculate 50% of the window's width or height
  const maxSize = `${Math.min(windowSize.width, windowSize.height) * 0.5}px`;

  return (
    <Zoom IconUnzoom={ZoomOutIcon} IconZoom={ZoomInIcon}>
      <img
        className={props.style}
        src={props.img_location}
        alt={props.alt_text}
        loading="lazy"
        style={{ maxWidth: maxSize, maxHeight: maxSize }}
      />
    </Zoom>
  );
}
