import axios from 'axios';


class ArtworkService {

    url;
    constructor() {

        if (!ArtworkService.instance) {
            ArtworkService.instance = this;
        }
        ArtworkService.instance = this;
    
        this.url = "https://qeehntd3z1.execute-api.us-east-2.amazonaws.com/Deployment";
        // this.url = "http://localhost:8080/api";

    }

    getHomePage = async () => {
        try {
            const resp = await axios.get(
                `${this.url}/catalog/home`
              );
            
            if (resp.status !== 200) {
                return false;
            }
            return resp.data;
        } catch (error) {
            // console.log(error);
            return false;
        }
        
            
    }

    getPortfolioPage = async () => {
        try {
            const resp = await axios.get(
                `${this.url}/catalog/portfolio`
              );
            
            if (resp.status !== 200) {
                return false;
            }
            console.log(resp.data)
            return resp.data;
        } catch (error) {
            console.log(error);
            return false;
        }                
    }

    getArtByCity = async (city) => {
        try {
            const resp = await axios.get(
                `${this.url}/catalog/portfolio/${city}`
              );
            
            if (resp.status !== 200) {
                return false;
            }
            console.log(resp.data)
            return resp.data;
        } catch (error) {
            console.log(error);
            return false;
        }                
    }

    uploadNewArt = async (selectedFile, metaData) => {
        try {
            const base64Image = await this.convertToBase64(selectedFile);

            const resp = await axios.post(
                `${this.url}/catalog`, 
                {
                    id: metaData.id,
                    title: metaData.title,
                    description: metaData.description,
                    width: metaData.width,
                    height: metaData.height,
                    fileName: metaData.fileName,
                    comment: metaData.comment,
                    city: metaData.city,
                    feature: metaData.feature,
                    imageBase64: base64Image
                }
              );
            if (resp.status !== 200) {
                return false;
            }            
            return true
        } catch (error) {
            console.log(error);
            return false;
        }

    }

    convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result.split(',')[1]);
            reader.onerror = error => reject(error);
        });
    }

    deleteArt = async (id) => {
        try {
            const resp = await axios.delete(
                `${this.url}/catalog/${id}`
              );
            
            if (resp.status !== 200) {
                return false;
            }
            // console.log(resp.data)
            return resp.data;
        } catch (error) {
            console.log(error);
            return false;
        }                    
    }
    
    editArt = async (body) => {
        
        try {
            const resp = await axios.put(
                `${this.url}/catalog`, body
              );
            
            if (resp.status !== 200) {
                return false;
            }                        
            return true
        } catch (error) {
            console.log(error);
            return false;
        }
    }
}
export default ArtworkService;