import React from "react";
import "../styles/bio.scss";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Link } from "react-router-dom";

export default function Bio() {
  return (
    <>
      <div className="page-container">
        <div className="bio-container">
          <img
            className="profile"
            src={process.env.PUBLIC_URL + "/dad_profile.jpg"}
            alt="profile"
          />
          <div>
            <div className="bio-header">
              <h3>Alan Robert Garry</h3>
              <a className="dissappearing-link" href="https://www.alanrobertgarry.com">
                www.alanrobertgarry.com
              </a>
              <a href="https://www.instagram.com/alanrobertgarry/">
                <InstagramIcon /> <div>alanrobertgarry</div>
              </a>
            </div>

            <div className="bio-content">
              <p className="bio-p1">
                Alan Robert Garry was born in 1950 in NYC. He attended
                Stuyvesant HS, and then Rensselaer Polytechnic Institute where
                he received an architecture degree in 1972. After college he
                pursued sculpture and painting, but eventually returned to
                architecture. In the 1980’s he started his own firm in NYC, and
                is still currently practicing, albeit less intensely.
              </p>

              <p className="bio-p1">
                Although he was painting plein air for several years, in 2020 he
                started studio oil painting. His passion for travel, cities &
                architecture, for building, is the impetus behind his painting.
                His process is a combination of drawing and drafting from his
                background in architecture, and plein air painting, and is fully
                described in his artist’s statement.
              </p>

              <p>
                Alan is currently submitting his work to regional, national and
                international juried exhibitions, and he exhibits each year at
                Armonk.
              </p>
            </div>
            <div className="awards">
              <div className="quadrant" id="q1">
                <h2>2024 Competitions & Exhibitions</h2>
                <ul>
                  <li>
                    Virginia Beach Art Center, From My Perspective, National
                    Juried Exhibition, Virginia Beach, VA
                  </li>
                  <li>
                    Schweinfurth Art Center, Made in NY, Juried Exhibition,
                    Auburn, NY
                  </li>
                  <li>
                    Cape Cod Museum of Art, Departures, International Juried
                    Exhibition, Cape Cod, MA
                  </li>
                  <li>
                    d’Art Center, Other, National Juried Exhibition, Norfolk, VA
                  </li>
                </ul>
              </div>

              <div className="quadrant" id="q2">
                <h2>2023 Competitions & Exhibitions</h2>
                <ul>
                  <li>
                    Viridian Gallery 32nd Annual International Juried
                    Exhibition, New York, NY
                  </li>
                  <li>
                    Rice Gallery Small Works National Juried Show, Overland
                    Park, KS
                  </li>
                  <li>Ridgewood Open Juried Show, Ridgewood, NJ</li>
                  <li>
                    Marblehead Arts Association Variations Juried Exhibition,
                    Marblehead, MA
                  </li>
                  <li>
                    Schweinfurth Art Center Made in NY, Juried Exhibition,
                    Auburn, NY
                  </li>
                  <li>
                    Kavanagh Gallery Environments, Juried Exhibition, Chicago,
                    IL
                  </li>
                  <li>
                    Texas National Juried Competition & Exhibition, Nacogdoches,
                    TX
                  </li>
                  <li>
                    The Studio Door Works of Excellence, Juried Exhibition, San
                    Diego, CA
                  </li>
                  <li>
                    Art League of Hilton Head 28th National Juried Exhibition,
                    Hilton Head Island, SC
                  </li>
                  <li>
                    Cape Cod Museum of Art In Tandem International Juried
                    Exhibition, Cape Cod, MA
                  </li>
                  <li>
                    MVA Gallery The Artists Vision – Juried Open Call,
                    Bethlehem, PA
                  </li>
                  <li>
                    d’Art Center, Scapes, National Juried Exhibition, Norfolk,
                    VA
                  </li>
                </ul>
              </div>

              <div className="quadrant" id="q3">
                <h2>2022 Competitions & Exhibitions</h2>
                <ul>
                  <li>
                    Cape Fear Studio National 2D Competition, Cape Fear, NC
                  </li>
                  <li>
                    Rice Gallery of Fine Art, City Life, National Juried Show,
                    Overland Park, KS, Third Place
                  </li>
                  <li>
                    d’Art Center, Scene, National Juried Exhibition, Norfolk,
                    VA, Honorable Mention
                  </li>
                  <li>
                    Hudson Valley Art Association Annual Juried Exhibition,
                    Lyme, CT, Award
                  </li>
                  <li>
                    Ridgefield Guild of Artists 12th Annual Juried Show,
                    Ridgefield, CT
                  </li>
                  <li>
                    Five Points Arts Center National Open Juried Show for Small
                    Pieces
                  </li>
                  <li>Bruce Museum Juried Show, Greenwich, CT</li>
                </ul>
              </div>

              <div className="quadrant" id="q4">
                <h2>2021 Competitions & Exhibitions</h2>
                <ul>
                  <li>
                    Five Points Arts Center National Open Juried Exhibition,
                    Torrington, CT
                  </li>
                  <li>
                    Providence Art Club National Open Juried Exhibition,
                    Providence, RI
                  </li>
                  <li>
                    Mamaroneck Artist’s Guild Small Works, Juried Exhibition,
                    Mamaroneck, NY, Award
                  </li>
                  <li>
                    Carriage Barn Arts Center Color+Art+Design, Juried
                    Exhibition, New Canaan, CT
                  </li>
                  <li>
                    Arts Westchester, Calling All Creatives, Curated Exhibition,
                    White Plains, NY
                  </li>
                </ul>
              </div>
            </div>
            <div className="artist-statement">
          <h2>What I am doing</h2>
          <p>
            The focus of my work is the builtscape: what people have built in
            relation to each other, and not particularly to the landscape. There
            is a geometry created by the multiple decisions made as people built
            without a pre-conceived framework. There is a beauty and movement in
            their assemblages, even in the most economically impoverished areas,
            as each builder had to deal with what their needs were and what had
            been constructed before them. I do see these works as a social
            statement: an acknowledgement that we are, in everything we do, part
            of the beauty of this world. The tension in my thinking comes from
            the idea that it is the last degree of elevation that brings the
            image from a high perspective to a pure geometry, however complex,
            of the plane. This is where the disciplines of pure geometry &
            drafting meet the reality of what has been built. It is the
            underlayment of the three dimensions that we see in perspective. I
            use the GPS coordinates and grids, as well as some elements of
            nature: parks, trees, fields, to tie the geometry and the buildings
            to the earth; so there is an understanding that this is not a purely
            abstract creation of my mind.
          </p>

          <h2>How am I doing it</h2>
          <p>
            My process starts with Google Earth. I am looking for a geometry
            and, if possible, a color scheme. If an image is out of focus, I
            pass, but if a geometry is strong enough, I will create a color
            palette. The next step is a graphite drawing on paper, which starts
            by tracing a projection of the computer image. Then it is redrawn to
            emphasize the desired shapes and relationships. This drawing is also
            used to determine the extent and edges of the final image, as well
            as the scale (close or far). After the drawing I will create an oil
            study sketch – occasionally colored pencil – at a smaller size than
            the final. I build my own frames & stretch canvas for the final
            painting because it helps me get a feel for the size of the image.
            The canvas is gessoed and sanded somewhat smooth for the initial
            graphite layout. The canvas is backed by MDF so that it is firm to
            the brush and is safe when shipped or transported. The computer
            image is then projected on the canvas, traced and then redrawn with
            graphite. Often the graphite will be part of the final image. The
            colors are applied fat over lean. I like to work over the white
            canvas, and the first layer is often thinned like a watercolor. The
            next layers are out of the tube, or with oil, or applied as a glaze.
            All application is with a brush, some quite fine (20/0). I believe
            the painting should be read from both across the room, and six
            inches away, which is where I am painting it from.
          </p>
        </div>
          </div>
          
        </div>
        
        
      </div>
      
    </>
  );
}
