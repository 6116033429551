import React from "react";
import "../styles/navbar.scss";

import { NavLink } from 'react-router-dom';

function Navbar() {
  return (
    <>

      <div className="navbar">
        <div className="navbar_container">
          <div>
            <NavLink exact to='/'>HOME</NavLink>

          </div>
          <div>
            <NavLink exact to='/contact'>CONTACT</NavLink>

          </div>
          <div>
            <NavLink exact to='/bio'>BIO</NavLink>

          </div>
        </div>
      </div>
    </>
  );
}

export default Navbar;
